import { useState, useEffect } from 'react';
import logo from '../../assets/images/ChatRMS_logo_preto.png';
import Chatbot from './components/Chatbot';
import NewCarousel from '../NewCarousel/NewCarousel';
import BotInterface from '../../interfaces/BotInterface';
import robot from '../../assets/svgs/robot.svg';
import generateSessionId from '../../utils/SessionIds';

const initialAssistentes: BotInterface[] = [
    {
        name: 'Sofia',
        content: 'Sofia é uma atendente de lanchonete que responde a perguntas a respeito de hamburgueres, combos e muito mais!',
        imageRef: robot,
        sessionId: '123456789',
        conversationUrl: 'https://flowise.datarms.com/api/v1/prediction/e422d28c-7f5e-4720-8063-7534bde9f038'
    },
    {
        name: 'Iara',
        content: 'Iara é a recepcionista de uma clínica que realiza agendamentos de consultas e atendimentos para os pacientes.',
        imageRef: robot,
        sessionId: '123456789',
        conversationUrl: 'https://flowise.datarms.com/api/v1/prediction/e64d244c-e62d-4bb8-a26c-5697ea7b6268'
    },
    {
        name: 'Lívia',
        content: 'Lívia é uma atendente de uma agência de turismo do Rio de Janeiro que ajuda os clientes a planejar suas viagens.',
        imageRef: robot,
        sessionId: '123456789',
        conversationUrl: 'https://flowise.datarms.com/api/v1/prediction/2e238f8b-b61e-40a2-8226-72caba2270c6'
    },
    // {
    //     name: 'Carlos',
    //     content: 'Carlos é um atendente de suporte técnico que ajuda com problemas de TI e soluções técnicas.',
    //     imageRef: robot,
    //     sessionId: '123456789',
    //     conversationUrl: 'https://flowise.datarms.com/api/v1/prediction/e422d28c-7f5e-4720-8063-7534bde9f038'
    // },
    // {
    //     name: 'Ana',
    //     content: 'Ana é uma assistente de vendas que ajuda com informações sobre produtos e realizações de pedidos.',
    //     imageRef: robot,
    //     sessionId: '123456789',
    //     conversationUrl: 'https://flowise.datarms.com/api/v1/prediction/e422d28c-7f5e-4720-8063-7534bde9f038'
    // },
    // {
    //     name: 'Pedro',
    //     content: 'Pedro é um assistente financeiro que responde a perguntas sobre faturas, pagamentos e financiamentos.',
    //     imageRef: robot,
    //     sessionId: '123456789',
    //     conversationUrl: 'https://flowise.datarms.com/api/v1/prediction/e422d28c-7f5e-4720-8063-7534bde9f038'
    // }
];

export default function Hero() {
    const [assistentes, setAssistentes] = useState<BotInterface[]>([]);
    const [activeBot, setActiveBot] = useState<BotInterface | null>(null);

    useEffect(() => {
        // Inicializa os assistentes com IDs de sessão únicos
        const updatedAssistentes = initialAssistentes.map(assistente => ({
            ...assistente,
            sessionId: generateSessionId()
        }));
        setAssistentes(updatedAssistentes);
        setActiveBot(updatedAssistentes[0]);
    }, []);

    const handleSetActiveBot = (bot: BotInterface) => {
        setActiveBot(bot);
    };

    // const updateBotMessages = (botId: string, messages: Message[]) => {
    //     setBotMessages(prevMessages => ({
    //         ...prevMessages,
    //         [botId]: messages,
    //     }));
    // };

    return (
        <div className='relative flex flex-col w-full fade-in' id='hero'>
            <div className='absolute -top-40 -left-40 w-80 h-80 bg-blue-500 rounded-full -z-10'></div>
            <div className='absolute -right-80 -bottom-20 w-80 h-80 bg-blue-500 rounded-full -z-10'></div>

            <div className='flex flex-col'>
                <div className='flex gap-10 my-[8vh] lg:flex-row flex-col'>
                    <section id='textoHero' className='flex flex-col gap-10'>
                        <img src={logo} className='h-[8vh] object-scale-down mt-8' alt='logo' />
                        <h1 className='text-4xl font-bold text-center lg:text-left'>
                            Transforme seu Atendimento com <b>Inteligência Artificial</b>
                        </h1>
                        <p className='text-xl text-center lg:text-left'>
                            Maximize a eficiência do seu atendimento com nossos agentes de Inteligência Artificial via WhatsApp. Responda instantaneamente, ofereça suporte 24/7 e aumente a satisfação do cliente.
                            <br />
                            <br />
                            Teste conversar gratuitamente com nossos bots!
                        </p>
                        <NewCarousel items={assistentes} activeBot={activeBot} setActiveBot={handleSetActiveBot} />
                    </section>
                    <section id='imagemHero' className='flex items-center justify-right md:w-[56vw] w-full h-full'>
                        {activeBot && (
                            <Chatbot
                                bot={activeBot}
                                messages={[]}
                                updateMessages={(e) => { }}
                            />
                        )}
                    </section>
                </div>
            </div>
        </div>
    );
}