import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import robot from '../../../assets/svgs/robot.svg';
import BotInterface from '../../../interfaces/BotInterface';
import ReactMarkdown from 'react-markdown';

interface Message {
    text: string;
    isUser: boolean;
}

interface ChatbotProps {
    bot: BotInterface;
    messages: Message[];
    updateMessages: (messages: Message[]) => void;
}

const Chatbot: React.FC<ChatbotProps> = ({ bot, messages: initialMessages, updateMessages }) => {
    const [messages, setMessages] = useState<Message[]>(initialMessages);
    const [input, setInput] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setMessages(initialMessages);
    }, [initialMessages]);

    useEffect(() => {
        const timer = setTimeout(() => {
            // if (messages.length === 0) {
            const initialBotMessage: Message = { text: "Olá! Como posso ajudar você hoje?", isUser: false };
            setMessages([initialBotMessage]);
            // }
        }, 2000);

        if (inputRef.current) {
            inputRef.current.focus();
        }

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        updateMessages(messages);
    }, [messages, updateMessages]);

    const handleSendMessage = async () => {
        if (input.trim() && !loading) {
            const userMessage: Message = { text: input, isUser: true };
            setMessages([...messages, userMessage]);
            setInput('');
            setLoading(true);

            try {
                const requestBody = {
                    question: input,
                    overrideConfig: { sessionId: bot.sessionId },
                    conversationUrl: bot.conversationUrl
                };

                const response = await axios.post(
                    "https://one.datarms.com/webhook/flowise_rms",
                    JSON.stringify(requestBody),
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        auth: {
                            username: 'chat_rms',
                            password: 'chat_rms_password'
                        }
                    }
                );

                console.log(response.data[0].text);

                const botMessage: Message = { text: response.data[0].text, isUser: false };
                setMessages(prevMessages => [...prevMessages, botMessage]);
            } catch (error) {
                console.error('Erro ao enviar mensagem:', error);
                const errorMessage: Message = { text: 'Erro ao enviar mensagem. Tente novamente.', isUser: false };
                setMessages(prevMessages => [...prevMessages, errorMessage]);
            } finally {
                setLoading(false);
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }
        }
    };

    useEffect(() => {
        if (!loading && inputRef.current) {
            inputRef.current.focus();
        }
    }, [loading]);

    if (!bot) {
        return <div>Carregando...</div>;
    }

    return (
        <div className="flex justify-center w-full h-full justify-end">
            <div className="relative w-[350px] h-[71vh] bg-white rounded-3xl shadow-2xl flex flex-col overflow-hidden border-4 border-gray-700 text-sm" id='chatbot-container'>
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-24 h-4 bg-black rounded-b-3xl"></div>
                <div className="absolute top-4 right-6 w-2 h-2 bg-black rounded-full"></div>

                <div className="flex flex-col flex-1 bg-white mt-8 overflow-hidden">
                    <div className="bg-[#25D366] text-white p-4 text-center text-lg flex gap-3 items-center">
                        <div className="rounded-full bg-white p-2 flex items-center justify-center">
                            <img src={robot} className="h-6 w-6 object-scale-down" alt='robot' />
                        </div>
                        {bot.name ?? 'Sofia'}
                    </div>
                    <div className="flex-1 p-4 overflow-auto" id='chatbot-bg'>
                        {messages.map((message, index) => (
                            <div key={index} className={`flex w-full ${message.isUser ? 'text-right justify-end' : 'text-left justify-start'}`}>
                                <div className={`fade-in p-2 my-2 rounded-tl-lg rounded-tr-lg max-w-[80%] ${message.isUser ? 'message-user self-end rounded-bl-lg' : 'message-bot self-start rounded-br-lg'}`}>
                                    <ReactMarkdown>{message.text}</ReactMarkdown> {/* Renderiza o texto como Markdown */}
                                </div>
                            </div>
                        ))}
                        {loading && (
                            <div className="p-2 my-2 rounded-lg max-w-[80%] bg-gray-200 self-start text-left">
                                ...
                            </div>
                        )}
                    </div>
                    <div className="p-4 bg-gray-200 flex">
                        <input
                            type="text"
                            className="flex-1 p-2 border border-gray-300 rounded-full"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && !loading && handleSendMessage()}
                            placeholder="Mensagem"
                            ref={inputRef}
                            disabled={loading} // Desabilita o input enquanto está carregando
                        />
                        <button
                            className="ml-2 p-2 bg-[#25D366] text-white rounded-full"
                            onClick={handleSendMessage}
                            disabled={loading} // Desabilita o botão enquanto está carregando
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFFFFF" viewBox="0 0 256 256"><path d="M240,127.89a16,16,0,0,1-8.18,14L63.9,237.9A16.15,16.15,0,0,1,56,240a16,16,0,0,1-15-21.33l27-79.95A4,4,0,0,1,71.72,136H144a8,8,0,0,0,8-8.53,8.19,8.19,0,0,0-8.26-7.47h-72a4,4,0,0,1-3.79-2.72l-27-79.94A16,16,0,0,1,63.84,18.07l168,95.89A16,16,0,0,1,240,127.89Z"></path></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chatbot;